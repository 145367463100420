import React from "react";
import ReactDOM from "react-dom/client";
import { PrivyProvider } from "@privy-io/react-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { PRIVY_APP_ID } from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient();

const Root = () => {
  return (
    // <React.StrictMode>
    <PrivyProvider
      appId={PRIVY_APP_ID}
      config={{
        loginMethods: ["email"],
        appearance: {
          showWalletLoginFirst: false,
          theme: "light",
          accentColor: "#676FFF",
          logo: "https://assets-global.website-files.com/63752ade0dbac24c119e17f7/64b6cda0f6a5e58b9ae04df8_beam.svg",
        },
        legal: {
          termsAndConditionsUrl:
            "https://assets-global.website-files.com/654021b25420a3364e320a74/654021c2b4b8058a9a2b1283_Ansible Labs_Business Terms of Use.pdf",
          privacyPolicyUrl:
            "https://assets-global.website-files.com/63752ade0dbac24c119e17f7/64dba51dfb45dfb2ea18cbb3_Ansible%20Labs%20Privacy%20Policy.pdf",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PrivyProvider>
    // </React.StrictMode>
  );
};

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
