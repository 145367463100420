/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { useAuth } from "../../contexts/Auth";
import Access from "./Access";
import Logs from "./Logs";
import IpAddresses from "./IpAddresses";

enum Tabs {
  ApiKeys = "ApiKeys",
  Logs = "Logs",
  Docs = "Docs",
  IpAddresses = "IpAddresses",
}

export default function Api() {
  const auth = useAuth();
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.ApiKeys);

  return (
    <>
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2">
          <h4 className="text-xl font-bold dark:text-black">Core API</h4>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {!auth.currentUser.selectedAccount?.hasAPIAccess && (
              <p className="mt-2 text-sm text-gray-700">
                Your account is not enabled for API access, please contact{" "}
                <a href="mailto:support@getbeam.cash" className="underline">
                  support@getbeam.cash
                </a>{" "}
                to request access.
              </p>
            )}
          </div>
        </div>
        <div className="border-b border-gray-200 dark:border-gray-700 flex direction-row align-center justify-between">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="me-2">
              <button
                onClick={() => setSelectedTab(Tabs.ApiKeys)}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 group ${
                  selectedTab === Tabs.ApiKeys
                    ? "text-lavender-600 border-lavender-600 dark:text-lavender-500 dark:border-lavender-500"
                    : "border-transparent"
                }`}
              >
                API Keys
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => setSelectedTab(Tabs.IpAddresses)}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 group ${
                  selectedTab === Tabs.IpAddresses
                    ? "text-lavender-600 border-lavender-600 dark:text-lavender-500 dark:border-lavender-500"
                    : "border-transparent"
                }`}
              >
                IP Addresses
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => setSelectedTab(Tabs.Logs)}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 group ${
                  selectedTab === Tabs.Logs
                    ? "text-lavender-600 border-lavender-600 dark:text-lavender-500 dark:border-lavender-500"
                    : "border-transparent"
                }`}
              >
                Logs
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => {
                  window.open(
                    "https://docs.beam.ansiblelabs.xyz/v3.0/reference/get-wallets",
                    "_blank",
                  );
                }}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 group ${
                  selectedTab === Tabs.Docs
                    ? "text-lavender-600 border-lavender-600 dark:text-lavender-500 dark:border-lavender-500"
                    : "border-transparent"
                }`}
              >
                Docs
                <ArrowTopRightOnSquareIcon height={15} className="ml-2" />
              </button>
            </li>
          </ul>
        </div>
        {auth.currentUser.selectedAccount?.hasAPIAccess && (
          <>
            {selectedTab === Tabs.ApiKeys && <Access />}
            {selectedTab === Tabs.IpAddresses && <IpAddresses />}
            {selectedTab === Tabs.Logs && <Logs />}
          </>
        )}
      </main>
    </>
  );
}
