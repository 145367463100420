/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import API from "../../api/api";
import { useAuth } from "../../contexts/Auth";
import Loader from "../../components/Loader";
import ConfirmDialog from "../../components/Confirmation";
import { format } from "date-fns";
import NewAPIKey from "../../components/NewAPIKey";
import { useToast } from "../../contexts/ToastContext";

export default function Access() {
  const auth = useAuth();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedAccount] = useState(
    auth.currentUser?.selectedAccount?.accountId,
  );
  const [apiKeys, setApiKeys] = useState([]);
  const [generateNewAPIKey, setGenerateNewAPIKey] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);

  const getAPIAccessToken = useCallback(async () => {
    if (selectedAccount) {
      setLoading(true);
      const { data } = await API.get(`business/${selectedAccount}/apiKeys`);
      setApiKeys(data);
      setLoading(false);
    }
  }, [selectedAccount]);

  const handleDeleteApiKey = async (apiKeyId: string) => {
    setLoading(true);
    await API.delete(`business/${selectedAccount}/apiKeys/${apiKeyId}`);
    setLoading(false);
    setApiKeys(apiKeys.filter((key) => key.id !== apiKeyId));
    showToast("API Key deleted successfully");
  };

  useEffect(() => {
    if (selectedAccount) {
      getAPIAccessToken();
    }
  }, [selectedAccount, getAPIAccessToken]);
  return (
    <>
      {loading && <Loader />}
      {selectedApiKey && (
        <ConfirmDialog
          isOpen={!!selectedApiKey}
          onClose={() => setSelectedApiKey(null)}
          onConfirm={(apiKey) => {
            handleDeleteApiKey(apiKey);
          }}
          onCancel={() => setSelectedApiKey(null)}
          title={`Delete ${selectedApiKey.apiKey} Key?`}
          description={`Are you sure you want to delete this API key? This action cannot be undone.`}
          confirmText="Delete"
          cancelText="Cancel"
          valueToPassBack={selectedApiKey?.id}
        />
      )}
      {generateNewAPIKey && (
        <NewAPIKey
          open={!!generateNewAPIKey}
          setOpen={setGenerateNewAPIKey}
          onSuccess={(data) => {
            setApiKeys([...apiKeys, data]);
          }}
        />
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Key
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Created by
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Created at
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-right text-sm font-semibold text-gray-900"
                    >
                      <button
                        type="button"
                        className="rounded bg-lavender-400 hover:bg-lavender-600 px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lavender-600"
                        onClick={(e) => {
                          e.preventDefault();
                          setGenerateNewAPIKey(true);
                        }}
                      >
                        Add API Key
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {apiKeys.map((key) => (
                    <tr key={key.id}>
                      <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6">
                        {key.description}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {key.apiKey}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {key.createdBy}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {format(key.createdAt, "MMMM dd, yyyy, h:mm a zzz")}
                      </td>
                      <td className="relative whitespace-nowrap p-4 text-right text-sm font-medium">
                        <a
                          href="#"
                          className="text-gray-500 hover:text-lavender-900"
                          onClick={() => setSelectedApiKey(key)}
                        >
                          Delete<span className="sr-only">, {key.name}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
