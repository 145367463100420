import { useEffect, useState, useCallback } from "react";
import { format } from "date-fns";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";

import API from "../api/api";
import { useAuth } from "../contexts/Auth";
import TransactionDetails from "../components/TransactionDetails";
import { Transaction } from "../types";
import Loader from "../components/Loader";
import CreateOnDemandTransactionModal from "../components/CreateOnDemandTransactionModal";
import { useToast } from "../contexts/ToastContext";
import ConfirmDialog from "../components/Confirmation";

export default function Transactions() {
  const { showToast } = useToast();
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const auth = useAuth();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  // const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openCreateTransactionModal, setOpenCreateTransactionModal] =
    useState(false);
  const [cancellingTransaction, setCancellingTransaction] = useState(false);
  const [transactionToCancel, setTransactionToCancel] =
    useState<Transaction | null>(null);

  const onClose = () => {
    setSelectedTransaction(null);
    setOpenCreateTransactionModal(false);
  };

  const getTransactionText = (transaction: Transaction) => {
    switch (transaction.type) {
      case "Payment":
        return "Payment";
      case "Trade":
        return "Trade";
      case "Deposit":
        return "Deposit";
      default:
        return "Transaction";
    }
  };

  const getAmount = (transaction: Transaction) => {
    if (transaction.type === "Payment") {
      return `$${transaction.usdAmount}`;
    } else if (transaction.type === "Trade" || transaction.type === "Deposit") {
      return `${transaction.amount} ${transaction.asset}`;
    }
  };

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await API({
        url: `/business/users/transactions/${auth?.currentUser?.selectedAccount?.accountId}`,
        method: "GET",
      });
      if (data) {
        setTransactions(data);
      }
    } catch (error: any) {
      console.error(error);
      setError(`Error fetching transactions: ${error.response?.data?.error}`);
    } finally {
      setLoading(false);
    }
  }, [auth?.currentUser?.selectedAccount?.accountId]);

  const cancelOnDemandTransaction = useCallback(
    async (transactionId: string) => {
      try {
        setCancellingTransaction(true);
        await API({
          url: `/business/${auth?.currentUser?.selectedAccount?.accountId}/transactions/${transactionId}`,
          method: "PATCH",
          data: {
            status: "cancelled",
          },
        });
      } catch (error: any) {
        console.error(error);
        setError(
          `Error cancelling transaction: ${error.response?.data?.error}`,
        );
      } finally {
        await fetchTransactions();
        setCancellingTransaction(false);
        setTransactionToCancel(null);
      }
    },
    [auth?.currentUser?.selectedAccount?.accountId, fetchTransactions],
  );

  const closeConfirmCancelTransactionDialog = () => {
    setTransactionToCancel(null);
  };

  useEffect(() => {
    if (auth?.currentUser?.selectedAccount?.accountId) {
      fetchTransactions();
    }
  }, [auth?.currentUser?.selectedAccount?.accountId, fetchTransactions]);

  useEffect(() => {
    if (error) showToast(error);
  }, [error, showToast]);

  // const handleDownload = useCallback(async () => {
  //   if (!downloading) {
  //     try {
  //       setDownloading(true);
  //       const { data, headers } = await API.get(
  //         `${BACKEND_URL}/business/${auth?.currentUser?.selectedAccount?.accountId}/transactions/${auth.currentUser?.id}/csv`
  //       );
  //       downloadFile({
  //         data,
  //         mimeType: "text/csv",
  //         fileName:
  //           headers["content-disposition"]?.split("filename=")?.[1] ??
  //           "transactions.csv",
  //       });
  //     } catch (error: any) {
  //       setError(`Error download transactions: ${error.response?.data?.error}`);
  //     } finally {
  //       setDownloading(false);
  //     }
  //   }
  // }, [
  //   auth.currentUser?.id,
  //   downloading,
  //   auth?.currentUser?.selectedAccount?.accountId,
  // ]);

  // const handleErrorClose = () => {
  //   setError(null);
  // };

  return (
    <>
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2 justify-between">
          <h4 className="text-xl font-bold dark:text-black">Transactions</h4>
          {auth?.currentUser?.selectedAccount?.offRampWithdrawalFrequency ===
            "onDemand" && (
            <button
              className="code text-md bg-white hover:bg-gray-700 font-bold text-black px-3 py-1 rounded-md border-2 border-lavender-500 hover:bg-lavender-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-lavender-800 focus:ring-opacity-50 transition-colors duration-200"
              data-clipboard-target="#code"
              onClick={() => setOpenCreateTransactionModal(true)}
            >
              + Transaction
            </button>
          )}
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {transactions.map((transaction) => (
                        <tr
                          key={transaction.id}
                          onClick={() => setSelectedTransaction(transaction)}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900">
                            {format(
                              new Date(transaction.createdAt),
                              "MMM dd, yyyy, h:mm a",
                            )}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {getTransactionText(transaction)}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {getAmount(transaction)}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {transaction.status}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {transaction.status === "awaitingFunds" && (
                              <button
                                className="code text-md bg-white hover:bg-gray-700 font-bold text-black px-3 py-1 rounded-md border-2 border-lavender-500 hover:bg-lavender-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-lavender-800 focus:ring-opacity-50 transition-colors duration-200"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTransactionToCancel(transaction);
                                }}
                                disabled={
                                  cancellingTransaction ||
                                  !!transactionToCancel ||
                                  transaction.status !== "awaitingFunds"
                                }
                              >
                                <TrashIcon height={16} />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      {selectedTransaction && (
        <TransactionDetails
          transaction={selectedTransaction}
          setClose={onClose}
        />
      )}
      <CreateOnDemandTransactionModal
        open={openCreateTransactionModal}
        setOpen={() => setOpenCreateTransactionModal(false)}
        setClose={() => {
          setOpenCreateTransactionModal(false);
          fetchTransactions();
        }}
      />
      {!!transactionToCancel && (
        <ConfirmDialog
          isOpen={!!transactionToCancel}
          onClose={closeConfirmCancelTransactionDialog}
          onConfirm={(transactionId) => {
            cancelOnDemandTransaction(transactionId);
          }}
          onCancel={closeConfirmCancelTransactionDialog}
          title={`Cancel Transaction?`}
          description={`Are you sure you want to cancel this transaction? This action cannot be undone.`}
          confirmText="Yes"
          cancelText="No"
          valueToPassBack={transactionToCancel?.id}
        />
      )}
    </>
  );
}
